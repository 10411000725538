<template>
  <div class="footer">
    <div class="foot-list">
      <div class="foot-ul">
        <img src="../../assets/images/logo.png" class="foot-logo" />
        <p>智慧引领未来，创新成就梦想</p>
        <p>联系邮箱：support001@huiyi-service.com</p>
        <p>深圳市慧意计算机有限责任公司 ICP备案/许可证号:<a href="https://beian.miit.gov.cn" target="_blank"
            class="beian">粤ICP备2024301268号</a></p>
        <p>公司办公地址：深圳市南山区中钢大厦M6栋5楼</p>
        <p>联系电话：17620432150</p>
        <p><span @click="xieyi(1)">服务协议</span><span style="margin-left:20px;" @click="xieyi(2)">隐私政策</span></p>
      </div>
      <div class="foot-ul">
        <h3>产品服务</h3>
        <a href="javascript:;">球彩AI</a>
      </div>
      <div class="foot-ul">
        <h3>用户中心</h3>
        <a href="javascript:;">文档中心</a>
        <a href="javascript:;">建议与反馈</a>
      </div>
      <div class="foot-ul">
        <h3>关于我们</h3>
        <a href="javascript:;">公司简介</a>
        <a href="javascript:;">联系我们</a>
      </div>
      <div class="foot-ul" style="text-align:center">
        <img src="../../assets/images/ewm.png" class="foot-ewm" />
        <a href="javascript:;" style="margin-top:1rem">官方客服咨询</a>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      Height: 0,
    };
  },
  created() {},

  methods: {
    xieyi(e){
      if(e==1){
        this.$router.replace({path:'/fwxy'})
      }else{
        this.$router.replace({path:'/yszc'})
      }

    }
  },
};
</script>

<style scoped>
.footer {
  width: 100%;
  background: #fff;
  font-size: 1.8rem;
  font-weight: bold;
  color: #1d2129;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 34rem; */
}
.foot-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 9rem 0px;
  width: 130rem;
}
.foot-ul {
  text-align: left;
}
.foot-ul .foot-logo {
  width: 17rem;
  height: 4rem;
  display: block;
  margin-top: -1.7rem;
}
.foot-ul p {
  font-weight: 400;
  font-size: 1.5rem;
  color: #888888;
  margin-top: 2rem;
  display: flex;
}
.foot-ul p span {
  cursor: pointer;
}
.foot-ul h3 {
  font-family: "AlibabaPuHuiTi";
  font-size: 1.8rem;
}
.foot-ul a {
  display: block;
  font-size: 1.5rem;
  color: #888;
  margin-top: 2rem;
  font-weight: 400;
}
.foot-ul .beian {
  margin-top: 0rem;
}
.foot-ul a:hover {
  color: #409eff;
}
.foot-ul .foot-ewm {
  width: 10rem;
  height: 10rem;
}
</style>