<template>
  <div class="home">
    <div ref="headDiv">
      <Header></Header>
    </div>

    <div class="content" :style="{ minHeight:isMobile?'0':Height + 'px' }">
      <transition name="move" mode="out-in">
        <RouterView></RouterView>
      </transition>
    </div>
    <div ref="footDiv">
      <Footer></Footer>
    </div>

  </div>

</template>

<script>
import Header from "./common/Header";
import Footer from "./common/Footer";
export default {
  name: "Home",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      Height: 0,
      path: localStorage.getItem("activeIndex"),
    };
  },
  created() {
    this.$nextTick(() => {
      this.Height =
        document.documentElement.clientHeight -
        this.$refs.headDiv.offsetHeight -
        this.$refs.footDiv.offsetHeight;
      console.log("this.Height", this.Height);
    });
    this.path = this.$route.path;
  },

  methods: {},
};
</script>
<style scoped></style>