<template>
  <div>
    <div class="header">
      <div class="header-auto">
        <div class="logo">
          <img src="../../assets/images/logo.png" />
        </div>
        <div class="lin-menu">
          <el-menu :default-active="activeIndex" :router="true" class="el-menu-demo" mode="horizontal"
            @select="handleSelect">
            <el-menu-item index="/server">公司介绍<p>Company Profile</p><span></span></el-menu-item>
            <el-menu-item index="/index">产品服务<p>Product</p><span></span></el-menu-item>
            <el-menu-item index="/contact">联系方式<p>Contact</p><span></span></el-menu-item>
          </el-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: null
    };
  },

  watch: {
    $route: {
      immediate: true,
      handler(route) {
        console.log("route", route)
        this.activeIndex = route.path;
      }
    }
  },

  mounted() {
    console.log("1234");
    if (localStorage.getItem('activeIndex')) {
      this.activeIndex = localStorage.getItem('activeIndex')
    } else {
      this.activeIndex = '/server'
    }
  },

  methods: {
    handleSelect(e) {
      console.log("e", e)
      localStorage.setItem('activeIndex', e)
    }

  },
};
</script>

<style scoped>
.header {
  width: 100%;
  height: 10.5rem;
  background: #eee;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10;
}

.header-auto {
  width: 130rem;
  height: 10.5rem;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo img {
  width: 25rem;
  height: 5.7rem;
}

.lin-menu /deep/ .el-menu {
  background: #eee;
}

.lin-menu /deep/ .el-menu-item {
  line-height: normal;
  height: 10.5rem;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: bold;
  color: #00417E;
  margin-left: 6em;
  background: #eee;
  box-sizing: border-box !important;
}

.lin-menu /deep/ .el-menu-item:hover {
  background-color: inherit;
}

.lin-menu /deep/ .el-menu-item:active {
  background-color: inherit;
}

.lin-menu /deep/ .el-menu-item:visited {
  background-color: inherit;
}

.lin-menu /deep/ .el-menu-item:link {
  background-color: inherit;
}

.lin-menu /deep/ .el-menu-item:not(.is-disabled):focus {
  background-color: inherit;
}

.lin-menu /deep/ .el-menu-item.is-active {
  color: #00417E;
  border-bottom: 0px;
}

.lin-menu /deep/ .el-menu-item.is-active span{
  position: absolute;
  width:100%;
  height:.6rem;
  background: #409EFF;
  left:0px;
  bottom:0px;
  z-index: 100;
  border-radius: 1rem;
  display: block;
}

.el-menu--horizontal>.el-menu-item.is-active{
  color: #00417E;
  border-bottom: 0px;
  border-top: none;
}
.lin-menu /deep/ .el-menu.el-menu--horizontal{
    border-bottom:0px;
}


.lin-menu /deep/ .el-menu-item p {
  font-size: 1.5rem;
  color: #00417E;
  font-weight: 100;
}


</style>